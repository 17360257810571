/* eslint-disable no-console */
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

import {
  dayStart, tzDate,
} from '@formkit/tempo';
import { users } from '@prisma/client';
import * as Sentry from '@sentry/nextjs';
import { geolocation } from '@vercel/functions';
import axios from 'axios';
import Crypto from 'crypto';
import jwt from 'jsonwebtoken';
import moment from 'moment';

import { UAParser } from 'ua-parser-js';

import {
  BetStatus, BonusDispatcher, BonusStatusSport, CalendarRange, ErrorCodes, GameResult, Gateway, TransactionStates, TransactionType, TransactionTypes,
  VoucherTypes,
} from '@/enums/core';
import { core } from '@/localization';
import { errors } from '@/localization/errors';

import { Encrypt } from '@/interfaces/core';

import {
  LOCAL, ALGORITHM, ROWS_PER_PAGE, JWT_JOIN,
} from '@/constants/core';

import { prisma } from './prisma';

// =============== FORMAT DATE

export const formatDate = (date: Date, short: boolean = false): string | Date => {
  const timeZone = 'America/El_Salvador';
  return short ? tzDate(dayStart(date), timeZone).toISOString().slice(0, 10) : tzDate(dayStart(date), timeZone);
};

export const startAndEndDay = (): { start: string, end: string } => {
  const start = moment().set('hour', 6).set('minute', 0).set('second', 0)
    .format('YYYY-MM-DD HH:mm:ss');
  const end = moment().add(1, 'days').set('hour', 6).set('minute', 0)
    .set('second', 0)
    .format('YYYY-MM-DD HH:mm:ss');
  return { start, end };
};

const randomString = (length:number, string:boolean = false):string => {
  const chars = string ? 'abcdefghijklmnopqrstuvwxyz' : '0123456789abcdefghijklmnopqrstuvwxyz';
  let data = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    data += chars[randomIndex];
  }

  return data;
};

export const extractNumberOfFakeUuid = (value: string): number => {
  return parseInt(value.split('-')[0].replace(/[^0-9]/g, ''), 10);
};

export const fakeUuid = (value: string): string => {
  const data = `${value}${randomString(8 - value.length, true)}-${randomString(4)}-4${randomString(3)}-y${randomString(3)}-${randomString(12)}`;
  return data;
};

export const checkHmacGateway = async (data: any, privateKey:any): Promise<boolean> => {
  const paramsArray:Array<any> = Object.values(data);
  const paramsArrayCopy = [...paramsArray];
  paramsArrayCopy.pop();
  paramsArrayCopy.push(privateKey);
  const dataHash = paramsArrayCopy.join('^');
  const signature = Crypto.createHash('sha256').update(dataHash).digest('hex');
  return signature === data.h_signature;
};

export const checkHmacGatewayForm = async (data: any): Promise<boolean> => {
  const hSignature = data.h_signature;
  // eslint-disable-next-line no-param-reassign
  delete data.h_signature;
  const paramsArray:Array<any> = Object.values(data);
  const dataJoin = paramsArray.join('^');
  const dataHash = `${dataJoin}^${process.env.SOMPOPO_PRIVATE_KEY}`;
  const signature = Crypto.createHash('sha256').update(dataHash).digest('hex');
  return signature === hSignature;
};

export const checkGatewayJWT = (token: string): boolean => {
  const result = jwt.verify(token, process.env.JWT_SECRET_KEY_TO_GATEWAYS!);
  return !!result;
};

export const errorCodeMessage = (error:number): string => {
  const array = [
    { code: ErrorCodes.NO_AUTHORIZATION, message: 'No autorizado' },
    { code: ErrorCodes.NO_JWT_TOKEN, message: 'No JWT token' },
  ];
  const result = array.find((item) => { return item.code === error; });
  return result?.message || '';
};

export const checkAllowedIp = (allowedIps: string, ip: string):boolean => {
  return allowedIps.split(',').includes(ip);
};

export const checkAuthorization = (headers: Headers): { message: string, status: boolean, token?: string } => {
  const authorization = headers.get('authorization');

  if (!authorization) return { message: errorCodeMessage(ErrorCodes.NO_AUTHORIZATION), status: false };

  const jwtToken = authorization.split(' ')[1];

  if (!jwtToken) return { message: errorCodeMessage(ErrorCodes.NO_JWT_TOKEN), status: false };

  return { message: '', status: true, token: jwtToken };
};

export const setGatewayJWT = (token: string): string | boolean => {
  if (token !== '') {
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    const payload = {
      data: `${token}${JWT_JOIN}${date}`,
    };
    const options = {
      // expiresIn: '1d',
      expiresIn: process.env.JWT_EXPIRES_TIME,
    };
    return jwt.sign(payload, process.env.JWT_SECRET_KEY_TO_GATEWAYS!, options);
  }
  return false;
};

// add or remove characters to the UID value
export const uidProcess = (type: number, value: string): string => {
  const characters = 50;
  let result = '';
  if (type === 1) {
    const start = Crypto.randomBytes(characters).toString('base64').slice(0, characters);
    const end = Crypto.randomBytes(characters).toString('base64').slice(0, characters);
    result = `${start}${value}${end}`;
  } else if (type === 2) result = value.slice(characters, -characters);
  return result;
};

export const calendarRange: Record<CalendarRange, string> = {
  [CalendarRange.TODAY]: core.today,
  [CalendarRange.YESTERDAY]: core.yesterday,
  [CalendarRange.THREE_DAYS_AGO]: core.threeDaysAgo,
  [CalendarRange.ONE_WEEK_AGO]: core.oneWeekAgo,
  [CalendarRange.LAST_30_DAYS]: core.last30Days,
};

export const betStatus = (value: string):string => {
  switch (value) {
    case BetStatus.LOST:
      return core.lost;
    case BetStatus.WON:
      return core.won;
    case BetStatus.CASHOUT:
      return core.cashout;
    case BetStatus.VOID:
      return core.canceladaVoid;
    case BetStatus.REJECTED:
      return core.canceladaRejected;
    case BetStatus.PENDING:
      return core.pending;
    case BetStatus.CASHOUT_PARCIAL:
      return core.cashoutParcial;
    case BetStatus.OPEN:
      return core.open;
    default:
      return '';
  }
};

export const transactionStatus = (value: number):string => {
  switch (value) {
    case TransactionStates.APPROVED:
      return core.transactionApproved;
    case TransactionStates.COMPLETE:
      return core.transactionCompleted;
    case TransactionStates.CANCELLED:
      return core.transactionCancelled;
    case TransactionStates.REJECTED:
      return core.transactionRejected;
    case TransactionStates.ISSUED:
      return core.transactionIssued;
    case TransactionStates.ROLLBACK:
      return core.transactionRollback;
    case TransactionStates.PENDING:
      return core.transactionPending;
    case TransactionStates.FINISHED:
      return core.transactionCancelled;
    default:
      return '';
  }
};

export const gameResult = (value: number): string => {
  switch (value) {
    case GameResult.BET:
      return core.lost;
    case GameResult.WIN:
      return core.won;
    case GameResult.ROLLBACK:
      return core.rollback;
    default:
      return '';
  }
};

export const getPageAndSkip = (pageParam: string | null): { page: number, pageSize: number, skip: number; } => {
  const page = pageParam ? parseInt(pageParam, 10) : 1;
  const pageSize = ROWS_PER_PAGE;
  const skip = (page - 1) * pageSize;
  return { page, pageSize, skip };
};

export const playerId = (limit: number = 10): number => {
  const time = new Date().getTime();
  return parseInt(Math.floor(Math.random() * time).toString().slice(0, limit), 10);
};

export const exception = async (message: any, info: { route: string, method: string, req: any }): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log('message', message);
  // eslint-disable-next-line no-console
  console.log('info', info);
  if (process.env.NEXT_PUBLIC_APP_ENV !== LOCAL && message !== null) {
    // eslint-disable-next-line no-console
    Sentry.captureException(message);
    await Sentry.flush(2000);
  }
};

export const encrypt = async (data: string | number): Promise<Encrypt | string> => {
  try {
    const key = Buffer.from(process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY!, 'base64');
    const iv = Buffer.from(process.env.NEXT_PUBLIC_CRYPTO_SECRET_IV!, 'base64');
    const cipher = Crypto.createCipheriv(ALGORITHM, key, iv);

    let encryptData = cipher.update(typeof data !== 'string' ? data.toString() : data, 'utf8', 'base64');
    encryptData += cipher.final('base64');

    const tag = cipher.getAuthTag().toString('base64');

    return { data: encryptData, tag };
  } catch (error: any) {
    return errors.tryAgain;
  }
};

export const decrypt = async (data: string, tag: string): Promise<string | string> => {
  try {
    if (data && tag) {
      const key = Buffer.from(process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY!, 'base64');
      const iv = Buffer.from(process.env.NEXT_PUBLIC_CRYPTO_SECRET_IV!, 'base64');

      const decipher = Crypto.createDecipheriv(ALGORITHM, key, iv);

      const authTag = Buffer.from(tag, 'base64');
      decipher.setAuthTag(authTag);
      const encryptedData = Buffer.from(data, 'base64');
      let decrypted = decipher.update(encryptedData);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      return decrypted.toString('utf8');
    }
    return errors.tryAgain;
  } catch (error: any) {
    return errors.tryAgain;
  }
};

export function signToken(data: any, expiresIn: string) {
  const options = {
    expiresIn,
  };
  const payload = {
    data,
  };
  return jwt.sign(payload, process.env.JWT_SECRET_KEY_TO_GATEWAYS!, options);
}

export function verifyToken(token: string) {
  try {
    return jwt.verify(token, process.env.JWT_SECRET_KEY_TO_GATEWAYS!);
  } catch (error) {
    return null;
  }
}

export const checkToken = async (data: string) => {
  try {
    const resToken = await fetch('/api/validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    });

    if (!resToken.ok) {
      return false;
    }

    const { data: token } = await resToken.json();

    return token;
  } catch (error) {
    return false;
  }
};

export const checkUserId = (userId: number): boolean => {
  if (userId && typeof userId === 'number' && userId !== undefined && !Number.isNaN(userId) && userId > 0) return true;
  return false;
};

export const checkNumber = (value: number): boolean => {
  if (value && typeof value === 'number' && value !== undefined && !Number.isNaN(value) && value > 0) return true;
  return false;
};

export const resendEmailVerification = async (): Promise<boolean> => {
  try {
    const request = await fetch('/api/resend-email-verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const response = await request.json();
    if (response.ok) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const sendEmailVerification = async (email: string): Promise<boolean> => {
  try {
    const request = await fetch('/api/send-email-verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const response = await request.json();
    if (response.ok) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export async function updateEmailVerification({
  email, id,
}:{ email: string, id: number }):Promise<boolean> {
  try {
    const query = await prisma.users.update({
      where: { id },
      data: {
        email,
        email_verified: true,
        kyc_level: 1,
        kyc_status: 0,
      },
    });

    return !!query;
  } catch (error) {
    return false;
  }
}

export async function findUserByEmail(email: string): Promise<{ id: number, uid: string, emailVerified: boolean, name: string, last_name: string, email: string, document_id: string, affiliate_id: string, kyc_level: number } | undefined> {
  let user: { id: number, uid: string, emailVerified: boolean, name: string, last_name: string, email: string, document_id: string, affiliate_id: string, kyc_level: number } | undefined;
  if (email !== null) {
    const query = await prisma.users.findFirst({
      where: {
        email,
      },
      select: {
        id: true,
        uid: true,
        name: true,
        last_name: true,
        email: true,
        email_verified: true,
        affiliate_id: true,
        kyc_level: true,
        documents: {
          select: {
            number: true,
          },
        },
      },
    });
    user = query ? {
      id: query.id,
      uid: query.uid,
      emailVerified: query.email_verified || false,
      name: query.name || '',
      last_name: query.last_name || '',
      email: query.email || '',
      document_id: query.documents[0]?.number || '',
      affiliate_id: query.affiliate_id || '',
      kyc_level: query.kyc_level || 0,
    } : undefined;
  }
  return user;
}

export async function checkPhoneExists(phone: string): Promise<boolean> {
  try {
    const user = await prisma.users.findFirst({
      where: {
        phone,
      },
      select: {
        id: true,
      },
    });
    return !!user;
  } catch (error) {
    await exception(error, { route: '/utils/core.ts: checkPhoneExists', method: 'GET', req: phone });
    return false;
  }
}

export async function getUserById(id: number) {
  const user = await prisma.users.findFirst({
    where: {
      id,
    },
    select: {
      uid: true,
      email: true,
    },
  });

  return user;
}

export async function findUserById(id: number): Promise<any> {
  let user = null;

  try {
    if (!id || typeof id !== 'number' || id === null) {
      return false;
    }
    user = await prisma.users.findFirst({
      where: {
        id,
      },
      select: {
        name: true,
        deposit_balance: true,
        available_balance: true,
        id: true,
        email: true,
        phone: true,
        created_at: true,
        nick: true,
        uid: true,
        status: true,
        last_name: true,
        altenar: true,
        last_session: true,
        kyc_level: true,
        provider: true,
        click_id: true,
        email_verified: true,
        btag: true,
        affiliate_id: true,
        topup_id: true,
        profiles: {
          select: {
            born_day: true,
            full_profile: true,
            first_deposit: true,
            deposits_limit: true,
          },
        },
      },
    });

    if (!user) {
      throw new Error('User not found');
    }

    return user;
  } catch (error) {
    return error;
  }
}

export const findUserByNick = async (nick: string): Promise<any> => {
  try {
    const user = await prisma.users.findFirst({
      where: {
        nick,
      },
    });
    return user;
  } catch (error) {
    return error;
  }
};

export const findUserByIdRecharge = async (id: string): Promise<any> => {
  try {
    const user = await prisma.users.findFirst({
      where: {
        topup_id: id.toLowerCase(),
      },
      select: {
        id: true,
        name: true,
        last_name: true,
      },
    });
    return { id: user?.id, name: user?.name, last_name: user?.last_name };
  } catch (error) {
    return error;
  }
};

export const findUserByOr = async (filter: any): Promise<{ id: number, name: string | null, last_name: string | null, email: string | null, status: boolean | null, phone: string | null, profiles: { document_id: string | null } | null } | null> => {
  try {
    const user = await prisma.users.findFirst({
      where: {
        OR: filter,
      },
      select: {
        id: true,
        name: true,
        last_name: true,
        email: true,
        status: true,
        phone: true,
        profiles: {
          select: {
            document_id: true,
          },
        },
      },
    });
    return user;
  } catch (error) {
    await exception(error, { route: '/utils/core.ts: findUserByOr', method: 'GET', req: filter });
    return null;
  }
};

export const findUser = async (userid: string | number) => {
  let user = await findUserById(Number(userid));
  if (!user) {
    user = await findUserByNick(userid.toString());
  }
  if (!user) {
    user = await findUserByIdRecharge(userid.toString());
  }
  return user;
};

export async function findUserByFirebaseId(uid: string): Promise<any> {
  let user = null;

  try {
    if (uid !== null) {
      user = await prisma.users.findFirst({
        where: {
          uid,
        },
        select: {
          id: true,
          uid: true,
        },
      });
    }
    return user as users | null;
  } catch (error) {
    return error;
  }
}

export const findUserByTransaction = async (transactionId: string): Promise<number | undefined> => {
  try {
    const transaction = await prisma.transactions.findFirst({
      where: {
        deposit_id: transactionId,
      },
      select: {
        user_id: true,
      },
    });

    const user = await prisma.users.findFirst({
      where: {
        id: transaction?.user_id,
      },
      select: {
        id: true,
      },
    });

    return user?.id;
  } catch (error) {
    return undefined;
  }
};

export async function altenarGetAccountDetails(userId: number, newToken: string) {
  let user = null;

  try {
    user = await prisma.users.update({
      where: {
        id: Number(userId),
      },
      data: {
        altenar: newToken,
      },
      select: {
        name: true,
        available_balance: true,
        deposit_balance: true,
        id: true,
        email: true,
        phone: true,
        nick: true,
        uid: true,
        last_name: true,
        altenar: true,
      },
    });
    return user;
  } catch (error) {
    return error;
  }
}

export async function findApuesta(apuestaid: string) {
  const cleanedApuestaid = apuestaid.trim();

  const bet = await prisma.bets.findFirst({
    where: {
      reference: cleanedApuestaid,
    },
    select: {
      id: true,
      user_id: true,
    },
  });

  return bet;
}

export async function findApuestaDuplicate(apuestaid: string) {
  const cleanedApuestaid = apuestaid.trim();

  const bet = await prisma.bets.findFirst({
    where: {
      reference: cleanedApuestaid,
    },
    select: {
      id: true,
      user_id: true,
    },
  });

  let data: { processed: boolean; saldo?: number; id_apuesta?: number; bonusBalance?: number; } = {
    processed: false,
  };

  if (bet) {
    const userBalance = await prisma.users.findFirst({
      where: {
        id: bet.user_id,
      },
      select: {
        deposit_balance: true,
        available_balance: true,
        betting_bonus_balance: true,
      },
    });

    data = {
      processed: true,
      bonusBalance: Number(userBalance?.betting_bonus_balance),
      saldo: Number(userBalance?.deposit_balance) + Number(userBalance?.available_balance),
      id_apuesta: bet.id,
    };
    return data;
  }
  return data;
}

export async function findApuestaTransaction(transactionID: string, ExternalUserID: string) {
  const eventid = await prisma.betsEvents.findFirst({
    where: {
      transaction_id: Number(transactionID),
    },
    select: {
      id: true,
    },
  });
  let data: { processed?: boolean; saldo?: number; id_evento_apuesta?: number; token?: string | null; bonusBalance?: number; } = {};

  if (eventid) {
    const userBalance = await prisma.users.findFirst({
      where: {
        id: Number(ExternalUserID),
      },
      select: {
        deposit_balance: true,
        available_balance: true,
        betting_bonus_balance: true,
      },
    });

    data = {
      processed: true,
      bonusBalance: Number(userBalance?.betting_bonus_balance),
      saldo: Number(userBalance?.deposit_balance) + Number(userBalance?.available_balance),
      id_evento_apuesta: eventid.id,
    };
    return data;
  }

  const userToken = await prisma.users.findFirst({
    where: {
      id: Number(ExternalUserID),
    },
    select: {
      altenar: true,
    },
  });

  data = {
    token: userToken?.altenar,
  };
  return data;
}

export async function createApuesta(apuesta: any) {
  return prisma.$transaction(async (tx) => {
    const bonusAmount = parseFloat(apuesta.data.monto_bono);
    // const bonusUpdateAmount = bonusAmount > 0 ? parseFloat(apuesta.data.Winnings) : 0;

    const newApuesta = await tx.bets.create({
      data: {
        user_id: apuesta.data.id_usuario,
        event_id: apuesta.data.id_evento,
        amount: parseFloat(apuesta.data.monto),
        bet_stake: apuesta.data.monto,
        created_at: apuesta.data.fecha_apuesta,
        reference: apuesta.data.BetReference,
        mode: apuesta.data.BetMode,
        description: apuesta.data.Description,
        frontend_type: apuesta.data.FrontendType,
        status: apuesta.data.BetStatus,
        sport_ids: apuesta.data.SportIDs,
        championship_ids: String(apuesta.data.ChampionshipID).substring(0, 250),
        event_ids: String(apuesta.data.EventIDs).substring(0, 250),
        ip: apuesta.data.ClientIP,
        event_count: apuesta.data.EventCount,
        event: apuesta.data.Events,
        system_bet: apuesta.data.IsSystem,
        odds: apuesta.data.Odds,
        winnings: parseFloat(apuesta.data.Winnings),
        bonus_amount: bonusAmount,
        // bonus_update_amount: bonusUpdateAmount,
        bonus_balance: parseFloat(apuesta.data.saldo_bono),
        bonus_account_id: apuesta.data.id_cuenta_bono,
      },

    });

    const updatedUser = await tx.users.update({
      where: {
        id: apuesta.data.id_usuario,
      },
      data: {
        altenar: apuesta.data.token,
      },
      select: {
        deposit_balance: true,
        available_balance: true,
        betting_bonus_balance: true,
      },
    });

    // Return both the new apuesta and the updated user info
    return { newApuesta, updatedUser };
  });
}

export async function updateApuesta(evento: any) {
  if (
    evento.data.tipo_evento === 'AwardWinnings'
    || evento.data.tipo_evento === 'CashoutBet'
  ) {
    await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        win_amount: evento.data.monto,
        bonus_update_amount: evento.data.monto_bono,
        bonus_update_balance: evento.data.saldo_bono,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
  } else if (evento.data.tipo_evento === 'NewCredit') {
    await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        win_amount: {
          increment: evento.data.monto,
        },
        bonus_update_amount: evento.data.monto_bono,
        bonus_update_balance: evento.data.saldo_bono,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
  } else if (evento.data.tipo_evento === 'NewDebit') {
    await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        debit_amount: evento.data.monto,
        bonus_update_amount: evento.data.monto_bono,
        bonus_update_balance: evento.data.saldo_bono,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
  } else if (evento.data.tipo_evento === 'stakeDecrease') {
    await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        stake_decrease_amount: evento.data.monto,
        bonus_update_amount: evento.data.monto_bono,
        bonus_update_balance: evento.data.saldo_bono,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
  } else if (evento.data.tipo_evento === 'RefundBet') {
    await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        refund_amount: evento.data.monto,
        bonus_update_amount: evento.data.monto_bono,
        bonus_update_balance: evento.data.saldo_bono,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
  } else if (evento.data.tipo_evento === 'LossSignal') {
    const updatedBet = await prisma.bets.update({
      where: {
        id: evento.data.id_apuesta,
      },
      data: {
        status: evento.data.BetStatus,
        last_event: evento.data.tipo_evento,
        last_event_date: new Date(),
      },
    });
    // eslint-disable-next-line no-console
    console.log('updatedBet', updatedBet);
  }
  const newevento = await prisma.betsEvents.create({
    data: {
      bet_id: evento.data.id_apuesta,
      event_type: evento.data.tipo_evento,
      transaction_id: evento.data.transactionID,
      amount: evento.data.monto,
      event_date: evento.data.fecha_evento,
      bonus_account_id: evento.data.id_cuenta_bono,
      bonus_user_id: evento.data.id_usuario,
    },
  });
  // eslint-disable-next-line no-console
  console.log('newevento', evento);
  if (evento.data.monto_bono && evento.data.monto_bono > 0) {
    // eslint-disable-next-line no-console
    console.log('evento.data.bonus_update_amount', evento.data.bonus_update_amount);
    const updatedBonus = await prisma.betBonuses.update({
      where: {
        bonus_account_id: evento.data.id_cuenta_bono,
      },
      data: {
        bonus_balance: evento.data.saldo_bono,
        updated_at: new Date(), // Explicitly set the updated_at if needed
      },
    });

    // eslint-disable-next-line no-console
    console.log('Update successful:', updatedBonus);
  }
  const userBalance = await prisma.users.findFirst({
    where: {
      id: evento.data.id_usuario,
    },
    select: {
      deposit_balance: true,
      available_balance: true,
      betting_bonus_balance: true,
    },
  });

  const data = {
    id_evento_apuesta: newevento.id,
    bonusBalance: Number(userBalance?.betting_bonus_balance),
    saldo:
      Number(userBalance?.deposit_balance)
      + Number(userBalance?.available_balance),
  };
  return data;
}

export async function createAwardBonus(evento: any) {
  const newevento = await prisma.betBonuses.update({
    where: {
      bonus_account_id: evento.data.id_cuenta_bono,
    },
    data: {
      transaction_id: evento.data.transactionID,
      bonus_balance: evento.data.bonusbalance,
      created_at: evento.data.fecha_evento,
      awarded_bonus: evento.data.monto,
      bonus_status: BonusStatusSport.AWARDED,
    },
  });

  const userBalance = await prisma.users.findFirst({
    where: {
      id: evento.data.id_usuario,
    },
    select: {
      deposit_balance: true,
      available_balance: true,
    },
  });
  const data = {
    id_bonus: newevento.id,
    saldo: Number(userBalance?.deposit_balance) + Number(userBalance?.available_balance),
  };

  return data;
}

export async function createBonus(bonus: any) {
  const playerBonus = await prisma.betBonuses.findFirst({
    where: {
      user_id: bonus.data.id_usuario,
      bonus_plan_id: bonus.data.bonusplan,
    },
    select: {
      id: true,
      bonus: {
        select: {
          bonus_expiration: true,
          dispatcher: true,
        },
      },
    },
  });
  const dateExpiry = new Date();
  let updatedOrNewBonus = null;
  if (playerBonus && playerBonus.id) {
    const days = playerBonus?.bonus?.dispatcher === BonusDispatcher.FIRST_DEPOSIT ? (playerBonus?.bonus?.bonus_expiration || 7) : (playerBonus?.bonus?.bonus_expiration || 14);
    dateExpiry.setDate(dateExpiry.getDate() + days);
    updatedOrNewBonus = await prisma.betBonuses.update({
      where: {
        id: playerBonus.id,
      },
      data: {
        transaction_id: bonus.data.TransactionId,
        bonus_balance: bonus.data.bonusbalance,
        bonus_plan_id: bonus.data.bonusplan,
        bonus_status: bonus.data.status,
        bonus_type: bonus.data.tipo_bono,
        awarded_bonus: bonus.data.monto,
        bonus_amount: bonus.data.bonusbalance,
        bonus_account_id: bonus.data.id_cuenta_bono,
        users: {
          connect: { id: bonus.data.id_usuario },
        },
        expiration_date: dateExpiry,
      },
    });
  }
  return updatedOrNewBonus;
}

export async function justCreateBonus(bonus: any) {
  const NewBonus = await prisma.betBonuses.create({
    data: {
      ...bonus,
    },
  });

  return NewBonus;
}

export const getDatGames = async (games: string[], type: string) => {
  // const favoritesParam = games.length > 0 ? `favorites=${games.join(',')}` : '';

  try {
    const res = await fetch('/api/casino/games', {
      method: 'POST',
      body: JSON.stringify({ favorites: games, type }),
      cache: 'force-cache',
      next: { revalidate: 60 },
    });
    const game = await res.json();
    return game;
  } catch (error) {
    return [];
  }
};

export const getDataGamesTournaments = async (games: string[], type: string, provider: string) => {
  // const favoritesParam = games.length > 0 ? `favorites=${games.join(',')}` : '';

  try {
    const res = await fetch('/api/casino/games', {
      method: 'POST',
      body: JSON.stringify({ favorites: games, type, provider }),
      cache: 'force-cache',
      next: { revalidate: 60 },
    });
    const game = await res.json();
    return game;
  } catch (error) {
    return [];
  }
};

export async function updateKYCLevel(id: string, kycLevel: number) {
  try {
    await prisma.users.update({
      where: {
        id: Number(id),
      },
      data: { kyc_level: kycLevel },
    });
    return { success: true };
  } catch (error) {
    throw new Error('Error updating KYC level');
  }
}

export async function requestVerification(data:any) {
  // eslint-disable-next-line no-console
  try {
    console.log('data', data);
    const userId = Number(data.user_id);
    const result = await prisma.users.update({
      where: {
        id: userId,
      },
      data: {
        kyc_status: data.kycStatus,
        kyc_level: data.kycLevel,
        name: data.name,
        last_name: data.last_name,
        profiles: {
          update: {
            document_id: data.document_id,
            document_type: data.document_type,
            born_day: new Date(data.born_day),
            gender: data.gender,
          },
        },
      },
    });
    // eslint-disable-next-line no-console
    console.log('result', result);

    const document = await prisma.documents.findFirst({
      where: {
        user_id: userId,
      },
    });
    if (document) {
      await prisma.documents.update({
        where: {
          id: document.id,
        },
        data: {
          type: data.document_type,
          number: data.document_id ? data.document_id.split(' ').join('') : '',
          selfie_url: data.selfie_url,
          document_front_url: data.document_front_url,
          document_back_url: data.document_back_url,
          external_id: data.external_id,
        },
      });
    } else {
      await prisma.documents.create({
        data: {
          type: Number(data.document_type),
          number: data.document_id ?? '',
          selfie_url: data.selfie_url,
          document_front_url: data.document_front_url,
          document_back_url: data.document_back_url,
          external_id: data.external_id,
          user_id: userId,
        },
      });
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    throw new Error('No se pudo actualizar la verificación KYC');
  }
}

export const moneyFormat = (amount: string | number) => {
  const data = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(amount));

  return data;
};

export const transactionType = (type: number): string => {
  switch (type) {
    case TransactionTypes.RECHARGE:
      return 'Recarga';
    case TransactionTypes.DEPOSIT:
      return 'Deposito';
    case TransactionTypes.WITHDRAWL:
      return 'Retiro';
    case TransactionTypes.ADJUST_DEP:
      return 'Ajuste de Deposito';
    case TransactionTypes.ADJUST_DISP:
      return 'Ajuste de Disponible';
    case TransactionTypes.RECHARGE_DISP:
      return 'Recarga de Disponible';
    default:
      return '';
  }
};

export const gatewayType = (type: number): string => {
  switch (type) {
    case Gateway.MANUAL:
      return 'Manual';
    case Gateway.BANK:
      return 'Banco';
    case Gateway.TIGO:
    case Gateway.CASH:
      return 'Efectivo';
    case Gateway.CHIVO:
      return 'Chivo';
    case Gateway.COINPAYMENTS:
      return 'CoinPayments';
    case Gateway.UNLIMIT:
    case Gateway.PAYPHONE:
    case Gateway.NICO:
      return 'Tarjeta Credito/Debito';
    default:
      return '';
  }
};
export async function verifyAgentBalance(agentId: string | number, amount: string | number): Promise<boolean> {
  const id = Number(agentId);
  try {
    const agent = await prisma.agente.findUnique({
      where: { id_agente: id },
    });

    if (!agent) {
      throw new Error(errors.agentNotFound);
    }

    const saldoSuficiente = Number(agent.saldo) >= Number(amount);
    return saldoSuficiente;
  } catch (error) {
    throw new Error(errors.agentBalanceError);
  }
}

export async function fetchXtremePushTransaction(user: any, amount: number) {
  // eslint-disable-next-line no-console
  console.log('user fetchXtremePushTransaction => ', user);
  const xpApiKey = process.env.XP_API_KEY;
  const today = new Date().toISOString().replace('T', ' ').slice(0, -5);
  const saldo = user.deposit_balance + user.available_balance;
  const payload = {
    apptoken: xpApiKey,
    event: 'deposito',
    user_id: user.id,
    user_attributes: {
      fecha_ultimo_deposito: today,
      primer_deposito: 1,
      saldo,
    },
    value: {
      monto: amount,
    },
    timestamp: today,
  };

  // eslint-disable-next-line no-console
  const apiUrl = 'https://api.us.xtremepush.com/api/external/hit/event';
  try {
    axios.post(apiUrl, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  const firstDepositValue = user.profiles?.first_deposit === true ? '0' : '1';

  if (user.click_id && user.click_id !== '') {
    try {
      // const url = firstDepositValue === '0' ? `https://www.pixelhere.com/et/event.php?advertiser=175686&cid=${user.click_id}&value=${amount}&id=d324e8` : `https://www.pixelhere.com/et/event.php?advertiser=175686&cid=${user.click_id}&id=290826&value=${amount}`;
      const url = firstDepositValue === '0' ? `https://www.imcounting.com/et/event.php?advertiser=290462&cid=${user.click_id}&value=${amount}&id=ed161f` : `https://www.imcounting.com/et/event.php?advertiser=290462&cid=${user.click_id}&value==${amount}&id=ebbed9`;
      axios.get(url).then((response) => {
        // eslint-disable-next-line no-console
        console.log('Pixelhere response data:', response.data);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error posting to Pixelhere:', error);
    }
  }

  try {
    const url = new URL('https://load.t.hondubet.com/payments');
    url.searchParams.append('user_id', user.id.toString());
    url.searchParams.append('uid', user.uid);
    url.searchParams.append('first_deposit', firstDepositValue);
    url.searchParams.append('currency', 'HNL');
    url.searchParams.append('payment_type', 'credit card');
    url.searchParams.append('transaction_id', Math.floor(Date.now() / 1000).toString());
    url.searchParams.append('country', 'HN');
    url.searchParams.append('value', amount.toString());

    // Fire-and-forget with proper handling
    axios
      .get(url.toString(), {
        headers: {
          'User-Agent': 'hondubet/1.0',
          Accept: '*/*',
        },
        timeout: 5000, // 5-second timeout
      })
      .then((response) => {
        // Consume response data to release socket
        console.log('Payment request succeeded:', response.data);
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          console.log('Payment request timed out');
        } else {
          console.log('Error in payment request:', error.message);
        }
      });
  } catch (error: any) {
    console.log('Error building payment request:', error.message);
  }

  return true;
}

export async function sendSportRadarDeposit(user: any, amount: number) {
  const sportradarUrl = user.profiles?.first_deposit ? `https://a.sportradarserving.com/pixel_s2s//?id=7238&aid=1641&auid=${user.uid}&dval=${amount}&cur=HNL` : `https://a.sportradarserving.com/pixel_s2s//?id=7237&aid=1641&auid=${user.uid}&dval=${amount}&cur=HNL`;

  (async () => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => { return controller.abort(); }, 5000);

      const response = await fetch(sportradarUrl, {
        method: 'GET',
        headers: {
          'User-Agent': 'hondubet/1.0',
          Accept: '*/*',
          Connection: 'keep-alive',
        },
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (response.ok) {
        // Consume response to ensure the socket is properly closed
        await response.text();
        console.log('Sportradar response processed successfully');
      } else {
        console.log(`Sportradar request failed: ${response.status}`);
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Sportradar request timed out');
      } else {
        console.log('Error in Sportradar request:', error);
      }
    }
  })();
  return true;
}

export async function sendContainerMediaDeposit(user: any, amount: number) {
  const firstDepositValue = user.profiles?.first_deposit === true ? '0' : '1';
  const transactionId = Math.floor(Date.now() / 1000).toString();
  const url = firstDepositValue === '0' ? `https://ctag.containermedia.net/api/s2s/secure/?id=673381cb65cd7c299f9f2fe32&uuid=${user.id}&affiliate=hondubet&country=HN&tid=${transactionId}&value=${amount}` : `https://ctag.containermedia.net/api/s2s/secure/?id=673381c765cd7c299f9f2eae&uuid=${user.id}&affiliate=hondubet&country=HN&tid=${transactionId}&value=${amount}`;
  try {
    axios
      .get(url, {
        headers: {
          'User-Agent': 'hondubet/1.0',
          Accept: '*/*',
          Connection: 'keep-alive', // Improve connection management
        },
        timeout: 5000, // Timeout to prevent hanging requests
      })
      .then((response) => {
        // Consume response to ensure socket cleanup
        console.log('ContainerMedia response data:', response.data);
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          console.log('ContainerMedia request timed out');
        } else {
          console.log('Error in ContainerMedia request:', error.message);
        }
      });
  } catch (error: any) {
    console.log('Error sending ContainerMedia postback:', error.message);
  }
}

export async function fetchXtremePushBet(type: TransactionType, data: any, saldo: number) {
  const xpApiKey = process.env.XP_API_KEY;
  const today = new Date().toISOString().replace('T', ' ').slice(0, -5);
  const payload = {
    apptoken: xpApiKey,
    user_id: data.id_usuario,
    user_attributes: {
      fecha_ultima_apuesta: today,
      saldo,
    },
    event: type,
    value: {
      monto: data.monto,
      betMode: data.BetMode,
      description: data.Description,
      status: data.BetStatus,
      events: data.Events,
      winnings: data.Winnings,
      odds: data.Odds,
      montobono: data.monto_bono,
      clientIP: data.ClientIP,
      eventCount: data.EventCount,
    },
    timestamp: today,
  };
  // eslint-disable-next-line no-console
  const apiUrl = 'https://api.us.xtremepush.com/api/external/hit/event';
  try {
    axios.post(apiUrl, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export async function sendBatchGamesToXtremePush(game: string, juegos: any) {
  const xpApiKey = process.env.XP_API_KEY;
  const today = new Date().toISOString().replace('T', ' ').slice(0, -5);
  if (!Array.isArray(juegos) || juegos.length === 0) {
    console.warn('No juegos provided to sendBatchGamesToXtremePush. Skipping request.');
    return;
  }

  const events = juegos.map((juego: any) => {
    return {
      user_id: juego.user_id,
      user_attributes: {
        fecha_ultimo_juego: today,
      },
      event: 'juego',
      value: {
        game,
        action: juego.result,
        amount: juego.amount,
        rolled_back: juego.rolled_back,
      },
      timestamp: today,
    };
  });
  if (events.length === 0) {
    console.warn('No events generated. Skipping API call.');
    return;
  }
  const payload = {
    apptoken: xpApiKey,
    events,
  };

  const apiUrl = 'https://api.us.xtremepush.com/api/external/hit/events';
  try {
    axios.post(apiUrl, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  }
}

export async function sendProfileEventToXtremePush({
  userId, contact, vendor, welcomeBonus, parent, pathname,
} : { userId: number, contact: string, vendor: number, welcomeBonus: string | null, parent: number, pathname: string }) {
  const xpApiKey = process.env.XP_API_KEY;
  const today = new Date().toISOString().split('T')[0]; // Calculate date onces

  const payload = {
    apptoken: xpApiKey,
    columns: ['user_id', `${vendor === 3 ? 'mobile_number' : 'email'}`, `${vendor === 3 ? 'sms_subscription' : 'email_subscription'}`, 'app_language', 'fecha_registro', 'welcome_bonus'],
    rows: [
      [userId, contact, 1, 'sp', today, welcomeBonus],
    ],
  };

  const data = {
    apptoken: xpApiKey,
    event: vendor === 3 ? 'registered_phone' : 'registered',
    value: {
      url: pathname,
    },
    user_id: userId,
    timestamp: today,
  };

  try {
    await fetch('https://api.us.xtremepush.com/api/external/import/profile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    await fetch('https://api.us.xtremepush.com/api/external/hit/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  try {
    await axios.get(`https://ctag.containermedia.net/api/s2s/secure/?id=673381c065cd7c299f9f2c3d&uuid=${userId}&affiliate=${parent}&country=HN`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error sending ContainerMedia postback:', error);
  }
}

export async function adcashPostback(clickId: string) {
  // axios.get(`https://www.pixelhere.com/et/event.php?advertiser=175686&cid=${clickId}&id=6449e9`)
  axios.get(`https://www.imcounting.com/et/event.php?advertiser=290462&cid=${clickId}&id=852452`)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
}

export async function upMediaPostback(userId: string, email: string, uid: string) {
  const url = new URL('https://load.t.hondubet.com/leads');

  // Append query parameters
  url.searchParams.append('user_id', userId);
  url.searchParams.append('uid', uid);
  url.searchParams.append('country', 'HN');
  url.searchParams.append('email', email);
  // Fire and forget axios request
  axios.get(url.toString())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Error sending upMedia postback:', error);
    });
}

export const topupCharacter = (): string => {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const data = letters[Math.floor(Math.random() * 25)];
  return data;
};

export const uploadFileToS3 = async ({ file, name }: { file: any, name: string }): Promise<string> => {
  try {
    const S3_BUCKET = process.env.NEXT_PUBLIC_VOUCHERS_S3_BUCKET ? process.env.NEXT_PUBLIC_VOUCHERS_S3_BUCKET : '';
    const REGION = process.env.NEXT_PUBLIC_VOUCHERS_S3_REGION ? process.env.NEXT_PUBLIC_VOUCHERS_S3_REGION : '';
    const S3_URL = 'https://sivarbetkyc.s3.eu-north-1.amazonaws.com/';
    const S3_FOLDER = 'vouchers/';

    const time = new Date().getTime();
    const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const key = `${S3_FOLDER}${time}-${uniqueId}-${name}`;

    const client = new S3Client({
      region: REGION,
      credentials: {
        accessKeyId: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY_ID ? process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY_ID : '',
        secretAccessKey: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY ? process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY : '',
      },
    });

    const command = new PutObjectCommand({
      Bucket: S3_BUCKET,
      Key: key,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    });

    const response = await client.send(command);

    if (response.$metadata.httpStatusCode === 200) {
      return `${S3_URL}${key}`;
    }
    return '';
  } catch (error) {
    console.log('error 1473 =>', error);
    return '';
  }
};

export async function updateFavoriteGames(favorites: string[]) {
  try {
    const res = await fetch('/api/casino/games/favorites', {
      method: 'PATCH',
      body: JSON.stringify({ favorites }),
      next: { revalidate: 60 },
    });

    const data = await res.json();
    return data.favoriteGames;
  } catch (error) {
    await exception(error, { route: '/utils/core.ts: updateFavoriteGames', method: 'DB', req: favorites });
    return { success: false };
  }
}

export const saveSession = async (userId: number, request: Request) => {
  try {
    const user = await prisma.users.findUnique({ where: { id: userId } });
    const ipRegex = /\b(?:\d{1,3}\.){3}\d{1,3}\b|\b(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}\b/;

    if (user) {
      const ip = request.headers.get('x-forwarded-for')?.match(ipRegex)?.[0] || null;
      const ltn = request.headers.get('x-vercel-ip-latitude') ?? '';
      const lon = request.headers.get('x-vercel-ip-longitude') ?? '';
      const {
        city, country, latitude, longitude,
      } = geolocation(request);
      const ua = new UAParser(request.headers.get('user-agent') || '');

      const currentTime = moment().valueOf();
      const queryData = {
        ip,
        browser: ua.getBrowser().name,
        os: ua.getOS().name,
        device: ua.getDevice().type, //
        model: ua.getDevice().model, //
        vendor: ua.getDevice().vendor, //
        city,
        country,
        latitude: latitude?.toString() ?? ltn.toString(),
        longitude: longitude?.toString() ?? lon.toString(),
      };

      if (user.last_session) {
        const lastSession = moment(user?.last_session).valueOf();
        const timeTransaction = 1000 * 60 * 60 * 24;
        const differenceInMinutes = Math.floor((currentTime - lastSession) / timeTransaction);
        if (differenceInMinutes > 1) {
          await prisma.sessions.create({
            data: {
              user_id: userId,
              ...queryData,
            },
          });
        }
      } else {
        await prisma.sessions.create({
          data: {
            user_id: userId,
            ...queryData,
          },
        });
      }
    }
  } catch (error) {
    console.log('🚀 ~ saveSession ~ error:', error);
    await exception(error, { route: '/utils/core.ts: saveSession', method: 'DB', req: userId });
  }
};
// ======================================> referrals <=====================================================
export const findparentType = async (affiliateCode: string) => {
  const isNumber = Number(affiliateCode);
  if (!Number.isNaN(isNumber)) {
    const agent = await prisma.agente.findUnique({
      where: {
        id_agente: Number(affiliateCode),
      },
    });
    return agent ? 'agente' : 'invalid';
  }
  const userTopup = await prisma.users.findFirst({
    where: {
      topup_id: affiliateCode,
    },
  });
  return userTopup ? 'user' : 'invalid';
};
// ======================================> end referrals <=====================================================

export const voucherStatus = (status: number) => {
  const voucherListTypes = [
    { value: VoucherTypes.ALL, label: 'Todo' },
    { value: VoucherTypes.REQUESTED, label: 'Solicitado' },
    { value: VoucherTypes.APPROVED, label: 'Aprobado' },
    { value: VoucherTypes.REJECTED, label: 'Rechazado' },
    { value: VoucherTypes.ANNULLED, label: 'Anulado' },
  ];

  const value = voucherListTypes.find((item) => { return item.value === status; })?.label;
  return value;
};

export const validateIdsForVoucherModule = (id:any):any => {
  if (!id) {
    return false;
  }

  const ids = process.env.NEXT_PUBLIC_USERS_IDS_VOUCHERS_HONDUBET;
  return ids?.split(',').includes(id.toString());
};

export const formatUnits = (unit: string) => {
  switch (unit) {
    case 'days':
      return 'Días';
    case 'hours':
      return 'Horas';
    case 'minutes':
      return 'Minutos';
    default:
      return '';
  }
};
